
import {
  Box,
 
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";

import { AppDispatch } from "../../redux/store";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getAllSurveys } from "../../service/UnsubscriptionSurvey";



interface ISurvey {
  id: string;
  email: string;
  phone: string;
  reason: string;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  fontWeight: "bold",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const TableWrapper = styled(TableContainer)({
  margin: "auto",
  marginTop: "1%",
  width: "80%",
}) as typeof TableContainer;

const Title = styled(Typography)({
  marginBottom: "16px",
});

function Survey() {
  const [adminData, setAdminData] = useState< ISurvey[]>([]);
  const [adminResponse, setAdminResponse] = useState<any>();
  const isMobile = useMediaQuery("(max-width:600px)");
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const [t, i18n] = useTranslation("global");
  const [page, setPage] = useState(0); // Page number starts from 0
  const [rowsPerPage, setRowsPerPage] = useState(10); // Rows per page

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    fetchAllSurveys(newPage, rowsPerPage); // Fetch games when page changes
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    fetchAllSurveys(0, parseInt(event.target.value, 10)); // Fetch games when rows-per-page changes
  };

  const fetchAllSurveys = async (page: number = 0, limit: number = rowsPerPage) => {
    const fetchedAdmins = await getAllSurveys(dispatch, isMobile, navigate, limit, page + 1);
    if (fetchedAdmins) {
      console.log("fetched Admins: ", fetchedAdmins);
      setAdminResponse(fetchedAdmins);
      setAdminData(fetchedAdmins.results);
    }
  };
  useEffect(() => {
    fetchAllSurveys();
  }, [dispatch, isMobile, navigate]);


  return (
    <Layout>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginLeft: "10%",
          marginRight: "10%",
          marginTop: "2%",
        }}
      >
        <Typography variant="h4" fontWeight="bold">
          {t("survey.t1")}
        </Typography>
     
      </div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginBottom: "10%",
        }}
      >
        <TableWrapper component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell sx={{ backgroundColor: "#ED670A" }} align="center">
                  {t("survey.t2")}
                </StyledTableCell>
                <StyledTableCell sx={{ backgroundColor: "#ED670A" }} align="center">
                  {t("survey.t3")}
                </StyledTableCell>
                <StyledTableCell sx={{ backgroundColor: "#ED670A" }} align="center">
                  {t("survey.t4")}
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {adminData.map((user) => (
                <StyledTableRow key={user.id}>
                  <TableCell align="center">{user.email}</TableCell>
                  <TableCell align="center">{user.phone}</TableCell>
                  <TableCell align="center">{user.reason}</TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
          {adminResponse && (
            <TablePagination
              component="div"
              count={adminResponse.totalResults || 0} // Total number of games
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            />
          )}
        </TableWrapper>
      </Box>
    </Layout>
  );
}

export default Survey;
