import Layout from "../../components/Layout";
import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getAwsLogs } from "../../service/AwsService";
import { useTranslation } from "react-i18next";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

const AwsLogs = () => {
  const [logs, setLogs] = useState<{ message: string }[]>([]);
  const { t } = useTranslation("global");
  const [dropdown, setDropdown] = useState(["INFO", "ERROR"]);
  const [selectString, setSelectString] = useState("INFO");
  const [search, setSearch] = useState(true);

  const handleChange = (event: SelectChangeEvent) => {
    setSelectString(event.target.value as string);
    setSearch(true);
  };

  const handleGetLogs = async () => {
    try {
      setSearch(true);
      const response: any = await getAwsLogs(selectString);
      console.log("resonse logs", response);

      setLogs(response.logs);
      setSearch(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (search) {
      handleGetLogs();
    }
  }, [selectString, search]);

  return (
    <Layout>
      <Box sx={{ marginTop: "30px", marginX: "50px" }}>
        <Box sx={{ display: "flex", justifyContent: "flex-start"}}>
          <Typography variant="h4">{t("logs.title")}</Typography>
        </Box>
        <Box
          sx={{
            minWidth: 120,
            marginTop: "20px",
            width: "100%",
            height:"40px",
            marginX: "auto",
            display: "flex",
            alignContent:"center",
            justifyContent: "space-between",
            flexDirection: "row",
          }}
        >
          <FormControl sx={{ width: "25%",display:"flex",justifyContent:"center",padding:"0px 2px" }}>
            <InputLabel id="demo-simple-select-label">{t("logs.selectLogs")}</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectString}
              label="Select logs"
              onChange={handleChange}
              sx={{
                ".MuiSelect-select": {
                  padding: "9.5px 16px", // Adjust padding as needed
                },
              }}
            >
              {dropdown.map((item, index) => {
                return (
                  <MenuItem key={index} value={item}>
                    {t(item)}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <Button
            onClick={handleGetLogs}
            variant="contained"
            sx={{
            //   m: 2,
              justifyContent: "center",
              backgroundColor: "#ED670A",
              height:"40px",
              "&:hover": { backgroundColor: "#d55a07" },
            }}
          >
            {t("logs.refreshButton")}
          </Button>
        </Box>
        <Box sx={{ marginTop: "30px" }}>
          {search ? (
            <Typography variant="h4" sx={{ textAlign: "center" }}>
              {t("logs.t1")}
            </Typography>
          ) : (
            <>
              {logs?.length > 0 && !search ? (
                logs?.map((item, index) => (
                  <Typography key={index}>
                    {item.message}
                    <hr></hr>
                  </Typography>
                ))
              ) : (
                <Typography variant="h4" sx={{ textAlign: "center" }}>
                  {t("logs.t2")}
                </Typography>
              )}
            </>
          )}
        </Box>
      </Box>
    </Layout>
  );
};

export default AwsLogs;
