import axios from "axios";
import { baseURL } from "../globals/constants";

export async function getAwsLogs(query: string) {
  try {
    const response = await axios.get(`${baseURL}/v1/logs?searchString=${query}`);
    return response.data;
  } catch (error) {
    console.log("error ", error);
    return error;
  }
}
