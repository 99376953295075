import axios from "axios";
import { baseURL } from "../globals/constants";
import { Dispatch } from "redux";
import { showErrorToast } from "../utils/Toast";
import { NavigateFunction } from "react-router-dom";

export const getAllSurveys = async (
    dispatch: Dispatch,
    isMobile: boolean,
    navigate: NavigateFunction,
    pageLimit?: number,
    pageNo?: number
  ) => {
    const token = localStorage.getItem("access_token");
    try {
      const response = await axios.get(
        `${baseURL}/v1/unsubscription/get-surveys?limit=${pageLimit}&page=${pageNo}`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Response in getAdminUsers is: ", response);
      return response.data;
    } catch (error: any) {
      if (error.response.status === 401) {
        showErrorToast("Session Expired. Please login", isMobile);
        localStorage.removeItem("access_token");
        navigate("/");
      }
      console.log("error ", error);
    }
  };
  