import { Box, Button, Grid, TextField, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Layout from "../../components/Layout";
import { AppDispatch } from "../../redux/store";
import { useAddBreefSettingForm, useAddSmsSettingForm } from "../../utils/forms";
import { Result } from "../manageBreef/BreefTable";
import { useTranslation } from "react-i18next";
import { TeamResult } from "../../manageTeam/TeamTable";
import { getBreefSetting, updateBreefSetting } from "../../service/BreefSettingService";
import { Sms } from "@mui/icons-material";
import SmsSetting from "./SmsSetting";

const UpdateBreefSetting = () => {
  const [t] = useTranslation("global");
  const location = useLocation();
  // const isEdit = new URLSearchParams(location.search).get("isEdit");
  const [breefSettingId, setBreefSettingId] = useState<string>("");
  const isMobile = useMediaQuery("(max-width:600px)");
  const [isEditing, setIsEditing] = useState(false);

  const BreefSettingformik = useAddBreefSettingForm(isEditing, breefSettingId || "", isMobile);
  const SmsSettingformik = useAddSmsSettingForm(isEditing, breefSettingId, isMobile);
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  const fetchBreefAndSmsSetting = async (page: number = 0) => {
    const fetchedSettings = await getBreefSetting(dispatch, isMobile, navigate, page + 1);
    if (fetchedSettings) {
      console.log("Fetched tenants: ", fetchedSettings);

      setBreefSettingId(fetchedSettings.results[0]?._id);
      BreefSettingformik.setValues({
        startScore: fetchedSettings.results[0]?.startScore,
        pointsPerSecond: fetchedSettings.results[0]?.pointsPerSecond,
        pointsPerRightAnswer: fetchedSettings.results[0]?.pointsPerRightAnswer,
        pointsPerWrongAnswer: fetchedSettings.results[0]?.pointsPerWrongAnswer,
        pointsMultiplier: fetchedSettings.results[0]?.pointsMultiplier,
      });
    }
  };
  useEffect(() => {
    fetchBreefAndSmsSetting();
  }, [dispatch, isMobile, navigate]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const data = {
        startScore: BreefSettingformik.values?.startScore,
        pointsPerSecond: BreefSettingformik.values?.pointsPerSecond,
        pointsPerRightAnswer: BreefSettingformik.values?.pointsPerRightAnswer,
        pointsPerWrongAnswer: BreefSettingformik.values?.pointsPerWrongAnswer,
        pointsMultiplier: BreefSettingformik.values?.pointsMultiplier,
      };
      await updateBreefSetting(data, breefSettingId);
      setIsEditing(false);
      navigate("/breef-setting");
    } catch (err) {
      console.error("Error editing user details: ", err);
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
  };
  const handleEdit = () => {
    setIsEditing(true);
  };


  return (
    <Layout>
      <Typography
        variant="h4"
        fontWeight="bold"
        style={{ display: "flex", alignItems: "center", marginLeft: "6%",justifyContent:"center",marginTop:"2%" }}
      >
        {t("updateBreefSetting.title")}
      </Typography>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          marginRight: "10%",
          marginTop: "2%",
          marginBottom: "1%",
        }}
      >
        <Typography
          variant="h4"
          fontWeight="bold"
          style={{ display: "flex", alignItems: "center", marginLeft: "6%" }}
        >
          {/* {isEdit === "true" ? "Update" : "View"} */}
          {t("updateBreefSetting.subTitle")}
        </Typography>
      </div>
      <Box sx={{ display: "flex", flexDirection: "column", marginX: "10%", marginBottom: "5%" }}>
        <Box component="form" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoFocus
                required
                margin="dense"
                id="startScore"
                name="startScore"
                label={t("updateBreefSetting.t1")}
                type="text"
                fullWidth
                variant="outlined"
                onChange={BreefSettingformik.handleChange("startScore")}
                value={BreefSettingformik.values.startScore}
                error={BreefSettingformik.touched.startScore && Boolean(BreefSettingformik.errors.startScore)}
                helperText={BreefSettingformik.touched.startScore && BreefSettingformik.errors.startScore}
                disabled={!isEditing}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                margin="dense"
                id="pointsPerSecond"
                name="pointsPerSecond"
                label={t("updateBreefSetting.t2")}
                type="text"
                fullWidth
                variant="outlined"
                onChange={BreefSettingformik.handleChange("pointsPerSecond")}
                value={BreefSettingformik.values.pointsPerSecond}
                error={BreefSettingformik.touched.pointsPerSecond && Boolean(BreefSettingformik.errors.pointsPerSecond)}
                helperText={BreefSettingformik.touched.pointsPerSecond && BreefSettingformik.errors.pointsPerSecond}
                disabled={!isEditing}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                margin="dense"
                id="pointsPerRightAnswer"
                name="pointsPerRightAnswer"
                label={t("updateBreefSetting.t4")}
                type="pointsPerRightAnswer"
                fullWidth
                variant="outlined"
                onChange={BreefSettingformik.handleChange("pointsPerRightAnswer")}
                value={BreefSettingformik.values.pointsPerRightAnswer}
                error={
                  BreefSettingformik.touched.pointsPerRightAnswer && Boolean(BreefSettingformik.errors.pointsPerRightAnswer)
                }
                helperText={
                  BreefSettingformik.touched.pointsPerRightAnswer && BreefSettingformik.errors.pointsPerRightAnswer
                }
                disabled={!isEditing}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                id="pointsPerWrongAnswer"
                name="pointsPerWrongAnswer"
                label={t("updateBreefSetting.t3")}
                type="text"
                fullWidth
                variant="outlined"
                onChange={BreefSettingformik.handleChange("pointsPerWrongAnswer")}
                value={BreefSettingformik.values.pointsPerWrongAnswer}
                error={
                  BreefSettingformik.touched.pointsPerWrongAnswer && Boolean(BreefSettingformik.errors.pointsPerWrongAnswer)
                }
                helperText={
                  BreefSettingformik.touched.pointsPerWrongAnswer && BreefSettingformik.errors.pointsPerWrongAnswer
                }
                disabled={!isEditing}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                id="pointsMultiplier"
                name="pointsMultiplier"
                label={t("updateBreefSetting.t5")}
                type="text"
                fullWidth
                variant="outlined"
                onChange={BreefSettingformik.handleChange("pointsMultiplier")}
                value={BreefSettingformik.values.pointsMultiplier}
                error={BreefSettingformik.touched.pointsMultiplier && Boolean(BreefSettingformik.errors.pointsMultiplier)}
                helperText={BreefSettingformik.touched.pointsMultiplier && BreefSettingformik.errors.pointsMultiplier}
                disabled={!isEditing}
              />
            </Grid>
          </Grid>
          <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
            {isEditing ? (
              <>
                <Button
                  onClick={handleCancel}
                  variant="outlined"
                  sx={{
                    mr: 1,
                    color: "#ED670A",
                    borderColor: "#ED670A",
                    "&:hover": { borderColor: "#d55a07" },
                  }}
                >
                  {t("updateTenant.t6")}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ backgroundColor: "#ED670A", "&:hover": { backgroundColor: "#d55a07" } }}
                >
                  {t("updateTenant.t7")}
                </Button>
              </>
            ) : (
              <Button
                onClick={handleEdit}
                  //  type="submit"
                  variant="contained"
                sx={{ backgroundColor: "#ED670A", "&:hover": { backgroundColor: "#d55a07" } }}
              >
              {t("updateBreefSetting.edit")}
              </Button>
            )}
          </Box>
        </Box>
      </Box>



<SmsSetting/>
    </Layout>
  );
};

export default UpdateBreefSetting;
