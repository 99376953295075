import axios from "axios";
import { baseURL } from "../globals/constants";
import {AddSmsSettingValues } from "../utils/forms";
import { Dispatch } from "redux";
import { showErrorToast } from "../utils/Toast";
import { NavigateFunction } from "react-router-dom";
import { setSmsSettingData } from "../redux/slices/SmsSlice";

export const getAllSmsSettings = async (
  dispatch: Dispatch,
  isMobile: boolean,
  navigate: NavigateFunction,
  pageLimit?: number,
  pageNo?: number
) => {
  const token = localStorage.getItem("access_token");
  try {
    const response = await axios.get(`${baseURL}/v1/sms-setting`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch(setSmsSettingData(response.data));
    return response.data.results;
  } catch (error: any) {
    console.log("error ", error);
    if (error.response.status == 401) {
      showErrorToast("Session Expired.Please login", isMobile);
      localStorage.removeItem("access_token");
      navigate("/");
    }
  }
};

export const addNewSmsContent = async (
  values: AddSmsSettingValues,
  isMobile: boolean,
  navigate: NavigateFunction
) => {
  const token = localStorage.getItem("access_token");
  try {
    const response = await axios.post(`${baseURL}/v1/sms-setting`, values, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error: any) {
    if (error.response.status == 401) {
      showErrorToast("Session Expired.Please login", isMobile);
      localStorage.removeItem("access_token");
      navigate("/");
    } else {
      const message =
        error?.response?.data?.message || "An error occurred while adding new category";
      showErrorToast(message, isMobile);
    }
  }
};

export const deleteSMSContent = async (id: string, isMobile: boolean, navigate: NavigateFunction) => {
  const token = localStorage.getItem("access_token");
  try {
    const response: any = await axios.delete(`${baseURL}/v1/sms-setting/${id}`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error: any) {
    if (error.response.status == 401) {
      showErrorToast("Session Expired.Please login", isMobile);
      localStorage.removeItem("access_token");
      navigate("/");
    }
    console.log("error ", error);
  }
};

export const getSMSContentById = async (
  categoryId: string,
  isMobile: boolean,
  navigate: NavigateFunction
) => {
  const token = localStorage.getItem("access_token");
  try {
    const response = await axios.get(`${baseURL}/v1/sms-setting/${categoryId}`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error: any) {
    if (error.response.status == 401) {
      showErrorToast("Session Expired.Please login", isMobile);
      localStorage.removeItem("access_token");
      navigate("/");
    }
    console.log("error ", error);
  }
};

export const updateSmsContent = async (
  values: AddSmsSettingValues,
  categoryId: string,
  isMobile: boolean,
  navigate: NavigateFunction
) => {
  const token = localStorage.getItem("access_token");
  try {
    const response = await axios.put(`${baseURL}/v1/sms-setting/${categoryId}`, values, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error: any) {
    if (error.response.status == 401) {
      showErrorToast("Session Expired.Please login", isMobile);
      localStorage.removeItem("access_token");
      navigate("/");
    } else {
      const message = error?.response?.data?.message || "An error occurred while updating category";
      showErrorToast(message, isMobile);
    }
  }
};
