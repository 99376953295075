import axios from "axios";
import { AddBreefSettingValues } from "../utils/forms";
import { baseURL } from "../globals/constants";
import { Dispatch } from "redux";
import { setBreefsData } from "../redux/slices/BreefsSlice";
import { showErrorToast, showSuccessToast } from "../utils/Toast";
import { NavigateFunction, Navigation, useNavigate } from "react-router-dom";
import { PointOfSale } from "@mui/icons-material";

export const CreateBreefSetting = async (
  values: AddBreefSettingValues,
  isMobile: boolean,
  navigate: NavigateFunction
) => {
  const token = localStorage.getItem("access_token");
  try {
    const value = {
      startScore: values.startScore,
      pointsPerSecond: values.pointsPerSecond,
      pointsPerRightAnswer: values.pointsPerRightAnswer,
      pointsPerWrongAnswer: values.pointsPerWrongAnswer,
      pointsMultiplier: values.pointsMultiplier,
    };
    const response: any = await axios.post(`${baseURL}/v1/breef-setting`, value, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error: any) {
    if (error.response.status == 401) {
      showErrorToast("Session Expired.Please login", isMobile);
      localStorage.removeItem("access_token");
      navigate("/");
    } else {
      const message = error?.response?.data?.message || "An error occurred while updating breef";
      showErrorToast(message, isMobile);
    }
  }
};

export const getBreefSetting = async (
  dispatch: Dispatch,
  isMobile: boolean,
  navigate: NavigateFunction,
  pageLimit?: number,
  pageNo?: number
) => {
  const token = localStorage.getItem("access_token");
  try {
    const response = await axios.get(
      `${baseURL}/v1/breef-setting?limit=${pageLimit}&page=${pageNo}`,
      {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log("Response in getTenantUsers is: ", response);
    return response.data;
  } catch (error: any) {
    if (error.response.status === 401) {
      showErrorToast("Session Expired. Please login", isMobile);
      localStorage.removeItem("access_token");
      navigate("/");
    }
    console.log("error ", error);
  }
};

export const updateBreefSetting = async (
  values: AddBreefSettingValues,
  id: string,
//   isMobile: boolean,
//   navigate: NavigateFunction
) => {
  const token = localStorage.getItem("access_token");
  try {
    const value = {
        startScore: values.startScore,
        pointsPerSecond: values.pointsPerSecond,
        pointsPerRightAnswer: values.pointsPerRightAnswer,
        pointsPerWrongAnswer: values.pointsPerWrongAnswer,
        pointsMultiplier: values.pointsMultiplier,
    };
    const response: any = await axios.put(`${baseURL}/v1/breef-setting/${id}`, value, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status >= 200 && response.status <= 301) {
      const errorMessage ="Score Setting updated successfully";
       
      showSuccessToast(errorMessage, true);
    }
    return response;
  } catch (error: any) {
    if (error.response.status === 401) {
      localStorage.removeItem("access_token");
    } else {
      const message = error?.response?.data?.message || "An error occurred while updating breef";
      showErrorToast(message, true);
    }
  }
};

export const deleteBreefSetting = async (id: string, isMobile: boolean, navigate: NavigateFunction) => {
    const token = localStorage.getItem("access_token");
    try {
      const response: any = await axios.delete(`${baseURL}/v1/breef-setting/${id}`, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      return response;
    } catch (error: any) {
      if (error.response.status == 401) {
        showErrorToast("Session Expired.Please login", isMobile);
        localStorage.removeItem("access_token");
        navigate("/");
      }
      console.log("error ", error);
    }
  };