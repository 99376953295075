import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import { useAddSmsSettingForm } from "../../utils/forms";
import { AppDispatch } from "../../redux/store";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { deleteSMSContent, getAllSmsSettings } from "../../service/SmsSettingService";

interface ChipData {
  textContent: string;
  _id: string;
}
export default function SmsSetting() {
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState<ChipData[]>([]);
  const [isDelete, setIsDelete] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [id, setId] = useState("");
  const isMobile = useMediaQuery("(max-width:600px)");
  const formik = useAddSmsSettingForm(isEdit, id, isMobile);
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const [isEditing, setIsEditing] = useState(false);
  const [t, i18n] = useTranslation("global");

  const handleCancel = () => {
    setIsEditing(false);
  };
  const handleOpenEdit = () => {
    setIsEditing(true);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    setId(data[0]?._id.toString());
    setIsEdit(true);
    e.preventDefault();
    formik.handleSubmit(e as any);
    setIsEditing(false);
    setIsEdit(false);

    setOpen(false);
  };

  useEffect(() => {
    if (isDelete) {
      setIsDelete(false);
    }
    fetchSmsSetting();
  }, [formik.isSubmitting, isDelete]);
  const fetchSmsSetting = async () => {
    const response: any = await getAllSmsSettings(dispatch, isMobile, navigate);
    console.log("response inside fetch categories", response);
    setData(response);

    formik.setValues({
      textContent: response?.[0].textContent || "",
    });
  };

  return (
    <Box>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          marginRight: "30%",
          marginTop: "2%",
          marginBottom: "1%",
        }}
      >
        <Typography
          variant="h4"
          fontWeight="bold"
          style={{ display: "flex", alignItems: "center", marginLeft: "6%" }}
        >
          {t("smsSetting.title")}
        </Typography>
      </div>

      <div style={{ display: "flex", justifyContent: "center", flexWrap: "wrap", gap: "10px" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginX: "10%",
            marginBottom: "5%",
            width: "100%",
          }}
        >
          <Box component="form" onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12}>
                <TextField
                  autoFocus
                  required
                  margin="dense"
                  id="textContent"
                  name="textContent"
                  label={t("smsSetting.textField")}
                  type="text"
                  fullWidth
                  multiline
                  rows={2}
                  sx={{ mt: 2 }}
                  variant="outlined"
                  onChange={formik.handleChange("textContent")}
                  value={formik.values.textContent}
                  error={formik.touched.textContent && Boolean(formik.errors.textContent)}
                  helperText={formik.touched.textContent && formik.errors.textContent}
                  disabled={!isEditing}
                />
              </Grid>
            </Grid>

            <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
              {isEditing ? (
                <>
                  <Button
                    onClick={handleCancel}
                    variant="outlined"
                    sx={{
                      mr: 1,
                      color: "#ED670A",
                      borderColor: "#ED670A",
                      "&:hover": { borderColor: "#d55a07" },
                    }}
                  >
                    {t("updateTenant.t6")}
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{ backgroundColor: "#ED670A", "&:hover": { backgroundColor: "#d55a07" } }}
                  >
                    {t("updateTenant.t7")}
                  </Button>
                </>
              ) : (
                <Button
                  onClick={handleOpenEdit}
                  //  type="submit"
                  variant="contained"
                  sx={{ backgroundColor: "#ED670A", "&:hover": { backgroundColor: "#d55a07" } }}
                >
                   {t("smsSetting.edit")}
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </div>
    </Box>
  );
}
